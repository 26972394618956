import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Contents from '../components/contents'
import { remarkHeaders } from '../utils/htmlAst'
import unified from 'unified'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import html from 'rehype-stringify'
import htmlParse from 'rehype-parse'
import SEO from '../components/seo'

class Page extends Component {
  render() {
    const page = this.props.data.strapiPages
    const rawhtml = unified().use(markdown).use(remark2rehype).use(html).processSync(page.content)
    const htmlAst = unified().use(htmlParse, { fragment: true }).parse(rawhtml.contents)
    const ast = remarkHeaders(htmlAst)
    return (
      <Layout>
        <SEO title={`${page.title}${page.tag ? ` | ${page.tag.tag}` : ''}`}></SEO>
        <Contents htmlAst={ast} />
        <div className="code-bg"></div>
      </Layout>
    )
  }
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const query = graphql`
  query Page($slug: String!) {
    strapiPages(slug: {eq: $slug}) {
      title
      tag {
        tag
      }
      content
    }
  }`